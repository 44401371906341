import {
  NavLink,
  type NavLinkProps,
  type UIMatch,
  useMatches,
} from '@remix-run/react';
import {Fragment, type HTMLAttributes, type ReactNode} from 'react';
import {cn} from '~/utils';
import {Scramble} from '~/components/scramble';

type BreadcrumbsItemProps = HTMLAttributes<HTMLElement> &
  NavLinkProps & {
    label: ReactNode;
  };

export const BreadcrumbsLink = ({
  children,
  label,
  ...props
}: BreadcrumbsItemProps) => {
  return (
    <NavLink
      itemProp="item"
      className={cn('text-foreground/80 hover:underline')}
      end
      {...props}
    >
      {children}

      <span itemProp="name">
        <Scramble playOnMount={false}>{label}</Scramble>
      </span>
    </NavLink>
  );
};

const BreadcrumbsSeparator = ({...props}: HTMLAttributes<HTMLElement>) => {
  return (
    <span
      className="pointer-events-none select-none text-sm text-foreground/50"
      {...props}
    >
      /
    </span>
  );
};

type BreadcrumbMatch = UIMatch<
  Record<string, unknown>,
  {breadcrumb: (data?: unknown) => JSX.Element}
>;

export const Breadcrumbs = ({
  className,
  ...props
}: HTMLAttributes<HTMLElement>) => {
  const matches = (useMatches() as unknown as BreadcrumbMatch[]).filter(
    ({handle}) => handle?.breadcrumb
  );

  if (matches.length <= 1) return null;

  return (
    <ol
      itemScope
      itemType="https://schema.org/BreadcrumbList"
      className={cn(
        'flex text-sm whitespace-nowrap items-center gap-1',
        className
      )}
      {...props}
    >
      {matches.map(({handle, data}, i) => (
        <Fragment key={i}>
          <li
            className={cn(
              'group contents overflow-hidden min-w-0',
              i > 0 && 'max-md:hidden'
            )}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            {i > 0 && <BreadcrumbsSeparator />}
            <span className="max-w-[20ch] truncate">
              {handle.breadcrumb(data)}
            </span>
            <meta itemProp="position" content={`${i + 1}`} />
          </li>
        </Fragment>
      ))}
    </ol>
  );
};
